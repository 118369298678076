import React from "react"
import { Footer } from "../Footer/Footer"
import { PageData } from "../../data"
import { SwitchNavigations } from "../SwitchNavigations/SwitchNavigations"
import { CookieBanner } from "../CookieBanner/CookieBanner"

export interface LegalPage {
  tag: string
  route: string
}

const LegalPageArray: LegalPage[] = [
  {
    tag: "AGB/Teilnahmebedingungen",
    route: "/agb",
  },
  {
    tag: "Impressum",
    route: "/impressum-2",
  },
  {
    tag: "Datenschutz",
    route: "/privacy",
  },
]

interface LayoutProps {
  withPadding?: boolean
  background?: "bg-white" | "bg-homepageBackground" | "bg-green-200"
  bordertop?: "border-t-2"
  secondLayout?: boolean
  flex?: boolean
}

export const Layout: React.FC<LayoutProps> = ({
  children,
  background = "bg-homepageBackground",
  bordertop,
  withPadding,
  secondLayout,
  flex,
}) => {
  return (
    <div
      className={`flex w-full flex-grow flex-col items-center ${background}`}
    >
      <CookieBanner />
      <SwitchNavigations>
        {bordertop && (
          <div className="w-full flex-col items-center border-t border-transparent lg:border-gray-200" />
        )}
      </SwitchNavigations>
      <div
        className={`relative flex w-full flex-grow flex-col items-center ${background} ${
          withPadding ? "pt-32 pb-72" : ""
        } ${secondLayout ? "lg:px-30 px-10" : ""} ${
          flex ? "flex flex-col" : ""
        }`}
        style={{ maxWidth: 1362 }}
      >
        {children}
      </div>
      <Footer contact={PageData.footerInfo.contact} tags={LegalPageArray} />
    </div>
  )
}
