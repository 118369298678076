import React from "react"
import { Link } from "gatsby"

interface FooterLegalPagesProps {
  tags: { tag: string; route: string }[]
}

const isBrowser = typeof window !== "undefined"

export const FooterLegalPages: React.FC<FooterLegalPagesProps> = ({ tags }) => {
  const isSafari =
    isBrowser &&
    window.navigator.userAgent.indexOf("Safari") !== -1 &&
    window.navigator.userAgent.indexOf("Chrome") === -1 &&
    window.navigator.userAgent.indexOf("Firefox") !== -1

  return (
    <ul className="mb-16 w-full list-inside list-disc md:mb-0 md:w-4/12 lg:w-1/3 lg:pl-12">
      {tags.map((content, index) => (
        <Link to={content.route} key={index}>
          <li className="text-2xl text-smaragd-400">
            <span
              className={`text-base font-medium text-white ${
                isSafari ? "" : "-ml-2"
              }`}
            >
              {content.tag}
            </span>
          </li>
        </Link>
      ))}
    </ul>
  )
}
