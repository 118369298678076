import React from "react"

export interface HamburgerNavElementsProps {
  title: string
  path?: string
  subtitles?: { title: string; path?: string }[]
  onRouteChange: (path?: string) => void
  paddingLeft: boolean
}

export const HamburgerNavElements: React.FC<HamburgerNavElementsProps> = ({
  title,
  path,
  subtitles,
  onRouteChange,
  paddingLeft,
}) => {
  const handleClick = () => {
    onRouteChange(path)
  }
  const handlePaths = (path: string | undefined) => {
    if (path === undefined) {
      return "fontTitilliumWebOrPlayFair-bold bg-hamburgerNavBar p-1"
    }
  }

  return (
    <div
      className={`my-1  w-full font-titilliumWeb ${paddingLeft ? "pl-8" : ""}`}
    >
      <div className="ml-6 mb-6 w-full overflow-scroll p-2">
        <div onClick={handleClick} className={`w-11/12 ${handlePaths(path)} `}>
          {title}
        </div>
        <div>
          {subtitles?.map((sub, index) => {
            return (
              <div key={index} className="">
                <HamburgerNavElements
                  title={sub.title}
                  path={sub.path}
                  onRouteChange={onRouteChange}
                  paddingLeft={true}
                  key={index}
                />
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}
