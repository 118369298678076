import React, { useEffect, useRef, useState } from "react"
import { headings } from "../../data"
import { HamburgerNavElements } from "../HamburgerNavElements/HamburgerNavElements"
import { navigate } from "gatsby"
import { ThreeStripes } from "../ThreeStripes"

export const HamburgerNavBar: React.FC = () => {
  const [toggle, setToggle] = useState(false)
  const labelRef = useRef<HTMLDivElement>(null)
  const [heightOfLabelBox, setHeightOfLabelBox] = useState(0)

  const handleClick = () => {
    setToggle(!toggle)
  }
  const onRouteChange = (path?: string) => {
    if (path === undefined) {
      return
    }
    navigate(path)
    setToggle(false)
  }
  const getLabel = (label: string) => {
    return (
      <div
        ref={labelRef}
        className="flex w-full flex-row justify-center rounded-lg border-2 bg-gray-200"
      >
        <span className="ml-2 flex w-full items-center justify-start">
          {label}
        </span>
        <button className="z-50 p-2" onClick={handleClick}>
          <ThreeStripes />
        </button>
      </div>
    )
  }

  useEffect(() => {
    setHeightOfLabelBox(labelRef.current?.clientHeight || 0)
  }, [])

  return (
    <div className="relative flex w-10/12 flex-col items-center overflow-visible">
      <div className="flex w-full justify-between text-sm">
        {getLabel("Seite wählen")}
      </div>
      <div
        className={`absolute left-0 right-0 z-20 flex flex-col items-center overflow-hidden bg-white duration-1000`}
        style={{
          transitionProperty: "height",
          top: heightOfLabelBox,
          height: toggle ? 800 : 0,
        }}
      >
        {headings.map((element, index) => {
          return (
            <HamburgerNavElements
              title={element.title}
              path={element.path}
              key={index}
              subtitles={element.subtitles}
              onRouteChange={onRouteChange}
              paddingLeft={false}
            />
          )
        })}
      </div>
    </div>
  )
}
