import { graphql, Link, useStaticQuery } from "gatsby"
import React, { useEffect, useRef, useState } from "react"
import { Paths } from "../../data"
import { HamburgerNavBar } from "../HamburgerNavBar/HamburgerNavBar"
import { Nav } from "../Navigation/Nav"
import { useWindowSize } from "../../hooks/useWindowSize"

const breakpoint = 986

// const isBrowser = typeof window !== "undefined"

export const SwitchNavigations: React.FC = ({ children }) => {
  // const [width, setWidth] = useState<number | undefined>(undefined)
  const width = useWindowSize()
  const [offset, setOffset] = useState(0)
  const isMobile = (width?.width ?? 0) < breakpoint

  // const isMobilePreRendering =

  const navRef = useRef<HTMLDivElement | null>(null)

  const data = useStaticQuery<{
    cms: {
      mainimages: {
        nodes: { mainImageFields: { mainimage: { sourceUrl: string } } }[]
      }
    }
  }>(
    graphql`
      query mainImage {
        cms {
          mainimages {
            nodes {
              mainImageFields {
                mainimage {
                  sourceUrl
                }
              }
            }
          }
        }
      }
    `,
  )

  useEffect(() => {
    setTimeout(() => {
      setOffset(navRef.current?.offsetHeight || 0)
    }, 200)
  }, [])

  return (
    <div
      ref={navRef}
      className={`flex w-full flex-col items-center bg-white text-navigationColor ${
        isMobile ? "pt-8 pb-4" : "pb-0 pt-0"
      }`}
    >
      <div
        className={`flex w-full flex-col items-center transition-opacity duration-200 ease-in-out ${
          width?.width ? "opacity-100" : "opacity-0 "
        }`}
      >
        <Link to={Paths.Index}>
          <img
            className={`z-30 mb-10 ${
              isMobile ? "w-mobile-nav-logo" : "w-nav-logo pt-6"
            }`}
            style={{
              minWidth: isMobile ? 82 : 172,
              minHeight: isMobile ? 56 : 138.91,
            }}
            src={
              data.cms.mainimages.nodes[0].mainImageFields.mainimage.sourceUrl
            }
            alt=""
          />
        </Link>
        {isMobile ? <HamburgerNavBar /> : <Nav offsetTop={offset} />}
        {children}
      </div>
    </div>
  )
}
