import { Link } from "gatsby"
import React, { useState } from "react"

export interface NavElementsProps {
  title: string
  path?: string
  subtitles?: { title: string; path?: string }[]
  offsetTop: number
  isSubtitle?: boolean
}

const isHover = (path?: string) => {
  return path === undefined
}

export const NavElement: React.FC<NavElementsProps> = ({
  title,
  path,
  subtitles,
  offsetTop,
  isSubtitle,
}) => {
  const [hover, setHover] = useState(false)

  const handleHoverAndClick = () => {
    setHover(true)
  }
  const handleMouseLeave = () => {
    setHover(false)
  }
  const handleColorForTitles = (path: string | undefined) => {
    if (path === undefined) {
      return "hover:text-smaragd-400"
    }
    if (path !== undefined) {
      return "hover:text-smaragd-400"
    }
  }

  const navElementBody = (
    <>
      <div
        className={`mx-4 flex font-navigation uppercase ${handleColorForTitles(
          path,
        )}`}
      >
        {title}
        {isHover(path) && (
          <div
            className={`ml-1 transform font-modules font-bold transition-transform duration-200 ease-in-out ${
              hover ? "-rotate-90" : ""
            }`}
          >
            3
          </div>
        )}
      </div>
      {subtitles && (
        <div
          className={`absolute flex flex-col items-start space-y-4 border-t-4 border-lineColor bg-white px-7 py-6 transition-opacity duration-200 ease-in-out ${
            hover ? "opacity-100" : "opacity-0"
          }`}
          style={{ top: offsetTop, zIndex: hover ? 50 : -1 }}
        >
          {subtitles?.map((element, index) => {
            return (
              <NavElement
                offsetTop={offsetTop}
                title={element.title}
                path={element.path}
                key={index}
                isSubtitle={true}
              />
            )
          })}
        </div>
      )}
    </>
  )
  return path === undefined ? (
    <div
      className={`flex cursor-pointer flex-row justify-center justify-center pb-8 font-titilliumWeb font-light `}
      onMouseEnter={handleHoverAndClick}
      onMouseLeave={handleMouseLeave}
    >
      {navElementBody}
    </div>
  ) : (
    <Link
      to={path}
      activeClassName="active-page"
      className={`flex cursor-pointer flex-col font-titilliumWeb font-light ${
        isSubtitle
          ? "w-64 py-4 text-sm font-medium tracking-tight transition-colors duration-200 ease-in-out hover:bg-grey-200"
          : ""
      } `}
    >
      {navElementBody}
    </Link>
  )
}
