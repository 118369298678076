import React from "react"

const mantras: { heading: string; text: string }[] = [
  {
    heading: "Yoga",
    text: "Yoga ist die Quelle für Kraft, Beweglichkeit und Gleichgewicht im Alltga.Unkompliziert und entspannt für jedermann.",
  },
  {
    heading: "Pilates",
    text: "Auf die Matte, fertig, los! Die sanften Übungen aus der Körpermittemit bewusster Atemtechnik bringen insbesondere Bauch und Rücken inForm und halten flexibel.",
  },
  {
    heading: "Balance",
    text: "Ein VITALIST findet seine Balance. Ein gestärkter, beweglicher Körper hält aufrecht und erzeugt Wohlbefinden als Ausgleich zum Alltagsstress.",
  },
]

export const Paths = {
  Index: "/",
  CourseBooking: "/course-booking",
  CourseSchedule: "/course-schedule",
  Ouroom: "/our-room",
  Nettiquette: "/netiquette",
  PersonalTraining: "/personal-training",
  Promotions: "/promotions",
  Coupons: "/coupons",
  Approach: "/approach",
  PriceList: "/price",
  PriceCards: "/price-cards",
  Team: "/team",
  Workshops: "/workshops",
  Campaigns: "/campaigns",
  Specials: "/specials",
}

export const headings: {
  title: string
  path?: string
  subtitles?: { title: string; path?: string }[]
}[] = [
  {
    title: "Startseite",
    path: Paths.Index,
  },

  {
    title: "Unsere Kurse",
    subtitles: [
      { title: "Kursplan", path: Paths.CourseSchedule },
      { title: "Specials", path: Paths.Specials },
      { title: "Kurse buchen", path: Paths.CourseBooking },
      { title: "Workshops", path: Paths.Workshops },
      { title: "Personal Training", path: Paths.PersonalTraining },
    ],
  },

  {
    title: "Karte kaufen",
    subtitles: [
      {
        title: "Preisliste",
        path: Paths.PriceList,
      },
      {
        title: "Karte kaufen",
        path: Paths.PriceCards,
      },
      {
        title: "Gutscheine",
        path: Paths.Coupons,
      },
    ],
  },
  {
    title: "Unser Studio",
    subtitles: [
      {
        title: "Trainer:innen",
        path: Paths.Team,
      },
      {
        title: "Räume",
        path: Paths.Ouroom,
      },
      {
        title: "Netiquette",
        path: Paths.Nettiquette,
      },
    ],
  },
  {
    title: "Anfahrt&Kontakt",
    path: Paths.Approach,
  },
]

export const PageData = {
  footerInfo: {
    contact: {
      company: "Vitalist GmbH & Co. KG",
      address: "Waldstr. 28",
      region: "30163 Hannover",
      phoneNumber: " +49(0) 511 696 829 70",
      fax: " +49(0) 511 696 829 71",
      email: " info@vita-list.de",
    },

    agbTag: "AGB/Teilnahmebedingungen",
    imprintTag: "Impressum",
    dataTag: "Datenschutz",
  },
  nettiquette: {
    content: [
      {
        heading: "Schuhe",
        text: "Yoga und Pilates wird barfuß praktiziert. Bitte ziehe Deine Schuhe direkt am Eingang aus und stelle sie in\n das dafür vorgesehene Regal. Wenn Du schnell kalte Füße bekommst, bringe Dir gerne warme Socken mit.\nStraßenschuhe in den Kursräumen sind unter keinen Umständen gestattet!",
      },
      {
        heading: "Einchecken",
        text:
          "Bitte melde Dich bei unserem Counter Team mit Deinem vollen Namen an,\n" +
          "auch wenn Du Dich online schon in den Kurs eingetragen hast oder Inhaber/in eines Abos bist.\n",
      },
      {
        heading: "Pünktlichkeit",
        text: "Sei 5 bis 15 Minuten vor Kursbeginn bei uns, um in Ruhe einzuchecken, Dich umzuziehen und anzukommen.\nWenn Du das erste Mal bei uns bist, eine neue Karte kaufen oder ein Abo abschließen möchtest,plane gerne ein paar Minuten mehr ein.Zu spät kommen kann jedem mal passieren.Bitte warte in diesem Fall ggf. die\n Anfangsentspannung ab und betrete dann leise den Kursraum.\nAußerhalb der Counter-Öffnungszeiten wird die Tür mit Kursbeginn geschlossen.",
      },
      {
        heading: "Kursraum",
        text: "Gib den Teilnehmern vor Dir genug Zeit, in Ruhe ihren Platz aufzuräumen und den Raum zu verlassen,\n bevor Du diesen für einen anschließenden Kurs betrittst. Bitte nimm nur Deine Wertsachen mit in den Raum\n und platziere diese im dafür vorgesehenen Regal. Lasse alles andere in der Kabine.Achte darauf, dass Dein Handy und andere Geräte vollständig ausgeschaltet sind.",
      },
      {
        heading: "Marterial",
        text: "Matten und Kursmaterial stellen wir Dir zur Verfügung. Bitte reinige Deine Matte mit 2 bis 3 Sprühstößenunseres\n Mattenreinigers und räume diese sowie Dein sonstiges Material ordentlich wieder an seinen Platz,\nso dass auch die nachfolgenden Teilnehmer in den Genuss eines aufgeräumten Kursraumes kommen.\nWenn Du Inhaber/in unseres 6- oder 12 Monats Abos bist, kannst Du Deine eigene Matte bei uns im Studio lagern. Bitte markiere diese mit Deinem vollen Namen.\nGerne kannst Du Dir ein eigenes Handtuch mitbringen und auf Deine Matte legen.",
      },
      {
        heading: "Duft",
        text: "Ein tiefer Atem ist ein wichtiger Bestandteil der Praxis. Nicht jeder Duft wird von jedem als gleich angenehm empfunden,\n einige Menschen reagieren auf bestimmte Duftstoffe sogar allergisch.\nVerzichte daher vor dem Unterricht auf stark duftende Deos, Bodylotions und Parfum.",
      },
      {
        heading: "Kursplan",
        text: "Im Kursplan auf unserer Homepage hast Du immer alle aktuellen Kurse, eventuelle\n Änderungen,\n Workshops, Specials und Aktionen im Blick. Regelmäßig reinschauen lohnt sich.",
      },
    ],
  },
  rooms: {
    heading: "Unsere Räume",
    text: "Unser Studio bietet Dir großzügige Räumlichkeiten der Extraklasse.\nIn unserem hellen, großen Kursraum (ca. 130 qm) findet jeder einen Platz.\nKleingruppen- und Personal Trainings finden in unserem kleinen Kursraum (ca. 20 qm) statt.\nBis zum Beginn Deines Kurses kannst Du es Dir in unserem Empfangsbereich mit einer Tasse Tee gemütlich machen.",
    subHeading: "Anfrage Vermietung Vitalist:",
    images: [
      {
        image: "https://via.placeholder.com/400x200",
      },
      {
        image: "https://via.placeholder.com/400x200",
      },
      {
        image: "https://via.placeholder.com/400x200",
      },
      {
        image: "https://via.placeholder.com/400x200",
      },
      {
        image: "https://via.placeholder.com/400x200",
      },
      {
        image: "https://via.placeholder.com/400x200",
      },
    ],
  },
  descriptions: {
    content: [
      {
        heading: "Yoga für alle:",
        text: "Im Rhythmus des Atems verbinden sich die Asanas in einem Bewegungsfluss – mal kraftvoll und dynamisch, mal ruhig und sanft. Immer mit Fokus auf anatomisch korrekte Ausrichtung. Durch unterschiedliche Varianten kommen sowohl Anfänger als auch Fortgeschrittene auf ihre Kosten.",
      },
      {
        heading: "Rücken-Yoga:",
        text: "Deine Yogaeinheit für den Rücken",
      },
      {
        heading: "Pilates:",
        text: "Ein beweglicher Körper wirkt immer jünger als ein unbeweglicher Körper. Stabilisiere mit Pilates deine Körpermitte, arbeite im Fluss deiner Atmung und kräftige deine Rumpfmuskulatur. Dieser Kurs ist sowohl für Einsteiger als auch für Geübte geeignet.",
      },
      {
        heading: "Relax Yoga:",
        text: "Deine entspannte Yogaeinheit",
      },
      {
        heading: "Prana Vinyasa:",
        text: "Prana Vinyasa® Yoga ist ein energetischer, kreativer und transformierender Yogastil. Die Begründerin Shiva Rea entwickelte diesen aus den Lehren von Krishnamacharya sowie weiteren Traditionen wie Tantra, Ayurveda und Bhakti Yoga.",
      },
      {
        heading: "Pilates Rücken:",
        text: "Ein Training zur Kräftigung der tiefliegen Muskeln der Körpermitte, die deinen Rücken stabilisieren und entlasten. Die Pilates Übungen schulen die Atmung, fördern die Haltung und machen deinen Körper und deine Muskeln geschmeidiger und beweglicher.",
      },
      {
        heading: "Flow Stretch:",
        text: "Eine Yoga Klasse im sanften Fluss. Für alle, die Lust auf eine Praxis mit fließenden Bewegungen haben, die den Körper mobilisieren und die Muskulatur sanft dehnen.",
      },
      {
        heading: "Vinyasa Flow:",
        text: "In diesem Yogastil verschmelzen Atem und Bewegung in dynamischen Abfolgen von sanft bis kraftvoll. Fließend, kreativ und immer wieder neu.",
      },
      {
        heading: "Yin Restore",
        text: "Ein Mischung aus den passiven Yogastilen Yin Yoga und Restorative Yoga. Mit Unterstützung von Hilfsmitteln wie Decken, Blöcken und Bolstern wird ohne muskuläre Anspannung länger in den Asanas verweilt und ein meditativer Zustand erreicht. Ganz nach dem Motto „Weniger ist manchmal mehr“.",
      },
      {
        heading: "Power Vinyasa Flow Level 2:",
        text: "Für alle erfahrenen Yogis, die Lust haben, kraftvoll und dynamisch zu praktizieren und sich an neue Asanas heranzuwagen. Schwitzen garantiert!",
      },
    ],
  },
  promotions: {
    heading: "Unsere Neujahrsaktion 'Setze deine guten Vorsätze um !'",
    text: (
      <>
        <div>
          Im Januar haben wir eine besondere Aktion für euch!
          <br /> Du hast dir für das Neue Jahr 2020 sportliche Vorsätze gesetzt?
          Dann komm vorbei und bekämpfe den <br />
          inneren post-weihnachtlichen Schweinehund mit uns gemeinsam.
          <br />
          <div>
            <br /> Auf jede 5er-Karte, die im Studio gekauft wird,bekommt ihr
            <span className="text-smaragd-400 "> 5 Euro Rabatt</span>
          </div>
          <br />
          <p className="text-smaragd-400">vom 02.01. bis 31.01.2020</p>
        </div>
      </>
    ),
    image: "https://via.placeholder.com/1000x500",
  },
  preventions: {
    heading: "Präventionskurse",
    text: "Unsere Präventionskurse sind zehn aufeinanderfolgende einstündige Kurseinheiten, die wöchentlich stattfinden und von deiner Krankenkasse bezuschusst werden.Dafür musst du an mindestens acht der zehn Einheiten teilnehmen. Späterer Eintritt ist also gegebenenfalls auch möglich.\n\nIn welcher Höhe ein Präventionskurs bezuschusst wird entscheidet jede Krankenkasse selbst. Gerne informiere Dich vorab bei Deiner Krankenkasse, welche Rückerstattung Du erhalten wirst. Nach dem Kursbesuch erhältst Du von uns eine Bescheinigung zur dortigen Vorlage.\n\nEine vorherige verbindliche Anmeldung unter info@vita-list.de ist erforderlich.Bitte gib bei Deiner Anmeldung Deinen vollen Namen, Deine Telefonnummer und Deine Adresse an.\nDie Kursgebühr beträgt 140 EUR und ist mit Beginn des Kurses zu entrichten.\n\nBitte beachte, dass Du mit dem Präventionsangebot einen festen Kurs mit Start- und Enddatum buchst.\nNicht genutzte Einheiten verfallen nach Ende des Kurses.\nPräventionskurse können nur bei vorheriger fester Buchung oder mit Abo besucht werden.Ein Besuch mit Kurskarten ist leider nicht möglich.",
    subHeading: "Die nächsten Präventionskurse",
    content: [
      {
        heading: "2.September",
        text: "immer mittwochs, 10.30 Uhr Kurs pausiert in den Herbstferien",
      },
      {
        heading: "24.September",
        text: "MamaWORKOUT Beckenbodenimmer donnerstags, 9.30 Uhr Kurs pausiert in den Herbstferien",
      },

      {
        heading: "24.September",
        text: "MamaWORKOUT Ganzkörperkräftigung immer donnerstags, 10.45 Uhr Kurs pausiert in den Herbstferien",
      },
      { heading: "", text: "" },
      {
        heading: "Prävention MamaWORKOUT mit Babybauch",
        text: "Ein kräftigendes Ganzkörpertraining mit speziellen Übungen für die Schwangerschaft. Die Übungen helfen dir, den Rücken zu entlasten, außerdem mobilisierst und dehnst du deinen Körper. Du bleibst fit und vital bis zur Geburt und darüber hinaus.",
      },
      {
        heading: "Prävention MamaWORKOUT",
        text: "Ein Kurs für Mütter, die in netter Atmosphäre ihre Fitness und Gesundheit verbessern wollen. Er dient dem Muskelaufbau der von der Geburt in Mitleidenschaft gezogenen Muskelgruppen, wie der Rumpfmuskulatur und dem Beckenboden. Außerdem erzielst du durch das Training eine Verbesserung der Haltung und wirst widerstandsfähiger für deinen Baby-Alltag.MamaWORKOUT-Beckenboden richtet sich an alle Frischmamis, die etwa 6-8 Wochen nach der Geburt loslegen möchten. Dein Baby, das noch nicht krabbelt, ist beim Kurs herzlich willkommen!MamaWORKOUT-Ganzkörperkräftigung richtet sich an alle Mütter nach der Rückbildung. Dein Baby, das noch nicht krabbelt, ist beim Kurs herzlich willkommen!MamaWORKOUT Outdoor richtet sich an alle Mamas, die die Rückbildung abgeschlossen und Lust auf ein knackiges Workout an der frischen Luft haben. Dein Kinderwagen wird zum Fitnessgerät! Treffpunkt ist vor unserem Studio in der Waldstraße 28 Bitte bringe eine eigene Matte und eine Auflage für Dein Baby zum Kurs mit.",
      },
    ],
  },
  team: {
    heading: "Team",
    text: (
      <>
        Ihr Herz schlägt für Yoga und Pilates. Sie sind absolute Profis auf
        ihrem Gebiet, arbeiten seit Jahren im Business und leben, was sie
        lehren. Unser <span className="text-smaragd-400">VITALIST-Team!</span>
      </>
    ),
    members: [
      {
        image: "https://via.placeholder.com/100x100",
        heading: "Maggie Pawelska",
        text: "Yoga ist seit 9 Jahren ein wichtiger Teil meines Lebens. Ich habe meine RYT-Yogalehrer Ausbildung in 2016 in Deutschland absolviert. Meine Praxis hat mir geholfen, auf der Yogamatte (und allgemein im Leben) stärker, flexibler und furchtlos zu werden. Ich liebe Yoga, weil ich mich dadurch gut fühle und Yoga inspiriert mich dazu, jeden Moment aufmerksamer zu leben. Mein Lieblingsplatz ist überall dort, wo die Menschen zusammenkommen, um zu schwitzen, zu atmen und ihre Grenzen zu brechen.Meine Klassen sind kraftvoll, spielerisch und mit Herz. Ich liebe es, meine Stunden als kreativ zusammenhängende Yoga-Flows aufzubauen, und sie dann mit einem Schuss Verspieltheit und einer Prise Inspiration zu verfeinern. All dies führt dazu, eure Praxis zu vertiefen und zu verbessern.",
      },
      {
        image: "https://via.placeholder.com/100x100",
        heading: "Thordis Goosses",
        text: "Über den Fitnessbereich kam Thordis zum Yoga. Sie ist zertifizierte Yogalehrerin (RYS 500 Yoga). Thordis unterrichtet dynamisch-fließende Vinyasa-Flow-Klassen sowie ruhige, kraftvolle Hatha-Yogastunden, immer mit Fokus auf der körpergerechten Ausrichtung. Ihre Klassen im Vitalist sind für alle Level geeignet. Neben ihrer eigenen Yogapraxis liebt sie das Laufen in der Natur als aktive Meditation. Ihr Motto: Wer immer tut, was er schon kann, wird immer sein, was er schon ist.",
      },
      {
        image: "https://via.placeholder.com/100x100",
        heading: "Nadine Bewernick",
        text: "“LET IT GO – LET IT FLOW!” Im Flow zu sein steht für Nadine für universelle Freiheit und Leichtigkeit. Inspiriert von ihrer Prana Flow Lehrerin Shiva Rea, dem Soboco Yoga System von Pushava und Devamaya und ihrer Ausbildung zum Yoga und Mindfullness Trainer, unterrichtet Nadine Meditation in Bewegung. In ihren Yogastunden taucht sie mit ihren Teilnehmern in diese dynamische, fließende und doch sehr kraftvolle Praxis ein. ",
      },
      {
        image: "https://via.placeholder.com/100x100",
        heading: "Frauke Wojtas",
        text: "Sie ist Sport – und Gymnastiklehrerin und seit über 15 Jahren mit viel Begeisterung im Fitness- und Gesundheitsbereich tätig. Ihre Schwerpunkte sind Pilates- und Rückenkurse. Seit 2015 leitet sie den norddeutschen Stützpunkt der Akademie für Prä-und Postnatales Training. Bewegung gehört zu ihrem Lebenselixier und sie glaubt, dass jeder ausreichend Zeit zu Verfügung hat und alles nur eine Frage der Planung ist. Ihr Motto: „Starte jetzt in ein bewegtes Leben“. ",
      },
      {
        image: "https://via.placeholder.com/100x100",
        heading: "Daniela Kamenzky",
        text: "Für Daniela sind Bewegung, Ernährung und Meditation wichtige Schlüssel zu einem gesünderen Körper und Geist. Mit viel Freude ist sie daher seit über 15 Jahren nebenberuflich als Trainerin im Gesundheitssport tätig und an unterschiedlichen Fortbildungen interessiert. Durch ein achtsames und vielfältiges Pilates-Training möchte sie ihre positiven Erfahrungen mit dieser Bewegungsart mit Menschen jeden Alters teilen und so Freude an Bewegung vermitteln, Kräftigung sowie mehr Beweglichkeit für den Körper bewirken, Entspannung für den Geist ermöglichen und zu einem gesteigerten Wohlbefinden beitragen.",
      },
      {
        image: "https://via.placeholder.com/100x100",
        heading: "Melanie Harre",
        text: "Melanie ist gelernte Arzthelferin und arbeitet seit Jahren mit chronisch erkrankten Menschen.2017 entdeckte sie Pilates für sich und ihre Gesundheit, was ihr die Motivation gab, selbst Pilates zu unterrichten. Im Praktizieren findet sie den Ausgleich zum stressigen Berufsleben. Sie geht dabei auf die individuellen Bedürfnisse und Möglichkeiten ihrer Kursteilnehmer*innen ein und erfreut sich an ihren Fortschritten.",
      },
      {
        image: "https://via.placeholder.com/100x100",
        heading: "Natalie Sappelt",
        text: "Natalie ist Vinyasa Yogalehrerin und hat unter anderem auch Ausbildungen in Ayurveda und Massage absolviert. Sie ist Expertin für ätherische Öle und bringt diese gerne in ihren Unterricht mit ein. Sie liebt besonders Restorative Yoga, um damit richtig zu entschleunigen.,",
      },
      {
        image: "https://via.placeholder.com/100x100",
        heading: "Christina Pockrand",
        text: "Yoga begleitete Christina bereits einige Jahre ihres Lebens, bevor sie sich 2018 für eine Ausbildung zur Vinyasa Yogalehrerin entschied. Sie mag die Verbindung von Körper und Geist durch Yoga sowie die Auszeit vom Alltag auf der Matte. Ihr Yogaunterricht ist ein Wechsel zwischen kraftvollen Einheiten und ruhigen, sehr bewussten Sequenzen. Dynamisch, aber mit einer gewissen Leichtigkeit und für jeden geeignet, denn „Yoga kann jeder“!,",
      },
      {
        image: "https://via.placeholder.com/100x100",
        heading: "Simon Hiltner",
        text: "Simon ist Kommunikationstrainer und Yogalehrer und schätzt die Asana Praxis genauso wie die Yogaphilosophie. In seinen Kursen vermittelt er im Wesentlichen Basics, wobei die Freude am Erleben immer im Vordergrund steht.,",
      },
      {
        image: "https://via.placeholder.com/100x100",
        heading: "Alice Manta",
        text: "Nach ca. 10 Jahren eigener Yoga-Praxis hat Alice 2016 ihre Ausbildung zur Vinyasa Yogalehrerin gemacht. Sie liebt es herausfordernde Flows zu erstellen, die trotz bewusster und langsamer Ausführung den Körper fordern und stärken. Yin-Yoga spielt aber auch eine sehr wichtige Rolle, nicht nur in ihrer eigenen täglichen Praxis. Ihre Flows enden mit entspannenden Posen, um einen Ausgleich für die zuvor fordernde Praxis zu schaffen. Weiterhin bietet Alice abseits der Matte auch SUP-Yoga an. Unter freiem Himmel auf einem wackeligen Board auf dem Wasser zu praktizieren, während Sonne und Wind über die Haut streicheln, ist eine ganz besondere Yoga-Erfahrung.",
      },
      {
        image: "https://via.placeholder.com/100x100",
        heading: "Jana Rosenstein",
        text: "Jana ist Physiotherapeutin und hat großes Interesse an Gesundheit und den Funktionsweisen des Körpers. Sie arbeitet hauptberuflich in einer Praxis für Physiotherapie. Durch verschiedene Fortbildungen erweitert sie ihr Repertoire ständig und wendet das erworbene Wissen sogleich an. Im Vitalist betreut sie das MamaWorkout für Schwangere, dort kann sie sowohl ihre zugewandte und fürsorgliche Art als auch ihre fachlichen Qualifikationen voll ausspielen.",
      },
      {
        image: "https://via.placeholder.com/100x100",
        heading: "Thaleia Hahn",
        text: "Derzeit in Elternzeit Etwas für sich selbst tun; das war der Weg der Thali zum Yoga gebracht hat. Sie hat dabei gelernt, dass Yoga gar nicht nur auf der Matte passiert, sondern auch sehr viel mit dem Umgang im Alltag mit sich selbst und anderen zu tun hat. Das schöne Gefühl sich mit sich selbst zu verbinden, gut so zu sein wie man selber ist und das tolle Yoga-Glow-Gefühl am Ende der Stunde, führt sie immer wieder auf die Matte. Mit ihrer Ausbildung zur Vinyasa Flow-Yogalehrerin kann sie die Freude an andere weitergeben und teilen. Sie möchte selbst immer Schüler bleiben, von anderen lernen, neugierig sein und Neuem mit einem spannenden, offenen Blick begegnen.",
      },
      {
        image: "https://via.placeholder.com/200x200",
        heading: "Nadine Maier",
        text: "Derzeit in Elternzeit Nadine absolvierte ihre Ausbildung an einem international führenden Institut für klassisches Pilates und führt nur selber Kurse nach der Originallehre von J.H. Pilates durch. Teilnehmer egal welchen Alters werden mit etwas Kontinuität schon bald die Erfolge dieses Sports erleben: Beweglichkeit und ein gesunder Körper!",
      },
    ],
  },
  approach: {
    heading: "Anfahrt",
    subHeading: "Kontakt",
    userHeading: "Senden Sie uns eine E-mail:",
    image: "https://via.placeholder.com/1200x400",
  },
  pricesListItems: {
    heading: "Preisliste",
    withoutContract: {
      subHeading: "Ohne vertragliche Bindung, ohne Voranmeldung",
      tableWithoutContract: [
        ["Kartentyp", "Gültigkeit", "Kosten", "Ermäßigung"],
        ["Schnuppern (3x)", "6 Wochen", "30,00 €", ""],
        ["5er Karte (Alle Kurse)", "direkt am Kauftag", "16,00 €", ""],
        [
          "8er Karte (Alle Kurse)",
          "12 Monate",
          "75,00 €",
          "20 % für Schüler Studenten Renten",
        ],
        ["15er Karte (Alle Kurse)", "12 Monate", "108,00 €", ""],
      ],
    },
    withContract: {
      subHeading: "Mit vertraglicher Bindung, ohne Voranmeldung",
      tableWithContract: [
        ["Vertragstyp", "Laufzeit", "Kosten", "Ermäßigung"],
        [
          "6 Monate",
          "Der Vertrag läuft automatisch nach Beendigung der Laufzeit ab. Preis-nachlass auf Workshops und Specials.",
          "64,00 €/Monat",
          "5 € für Schüler Studenten Rentner",
        ],
        ["12 Monate", "", "54,00 €/Monat", ""],
      ],
    },
  },
}
