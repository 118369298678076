import React from "react"

export const ThreeStripes: React.FC = () => {
  return (
    <div className="flex flex-col items-center">
      <div className="h-1 w-6 bg-captchaSendButton" />
      <div className="my-1 h-1 w-6 bg-captchaSendButton" />
      <div className="h-1 w-6 bg-captchaSendButton" />
    </div>
  )
}
