import React from "react"
import CookieConsent from "react-cookie-consent"

export enum cookie {
  VITALIST = "vitalist-cookie",
}

// since we can only store strings in cookies
export enum possibleCookieValues {
  accepted = "true",
  declined = "false",
}

export const CookieBanner: React.FC = () => {
  return (
    <CookieConsent
      location="bottom"
      buttonText="Akzeptieren"
      declineButtonText="Ablehnen"
      cookieName={cookie.VITALIST}
      style={{
        background: "#444444",
        fontSize: "18px",
        fontFamily: "Titillium Web",
      }}
      buttonStyle={{
        fontFamily: "sans-serif",
        color: "white",
        backgroundColor: "#4e9534",
        fontSize: "18px",
      }}
      declineButtonStyle={{
        fontFamily: "sans-serif",
        color: "white",
        backgroundColor: "#777777",
        fontSize: "18px",
      }}
      flipButtons
      //number in days
      expires={150}
      enableDeclineButton
    >
      Diese Website verwendet Cookies, um die Benutzerfreundlichkeit zu
      verbessern.{" "}
    </CookieConsent>
  )
}
