import React from "react"
import { NavElement } from "../NavigationContainer/NavElement"
import { headings } from "../../data"

interface NavProps {
  offsetTop: number
}

export const Nav: React.FC<NavProps> = ({ offsetTop }) => {
  return (
    <div className="flex w-full justify-center" style={{ width: 900 }}>
      {headings.map((element, index) => (
        <NavElement
          title={element.title}
          path={element.path}
          key={index}
          subtitles={element.subtitles}
          offsetTop={offsetTop}
        />
      ))}
    </div>
  )
}
