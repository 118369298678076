import React from "react"

export type ContactDetails = {
  company: string
  address: string
  region: string
  phoneNumber: string
  fax: string
  email: string
}
export type ContactDetails1 = {
  firstPart: { company: string; address: string; region: string }[]
  secondePart: { phoneNumber: string; fax: string; email: string }[]
}[]

interface ContactProps {
  detailHeading: boolean
  contact: ContactDetails
  marginLeft: boolean
  // detailContact: ContactDetails1
}

export const Contact: React.FC<ContactProps> = ({
  contact: { company, address, region, phoneNumber, fax, email },
  detailHeading,
  marginLeft,
  children,
}) => {
  const margin = () => {
    const contactMargin: string[] = [""]
    if (marginLeft) {
      contactMargin.push("")
    }
    return contactMargin.join(" ")
  }
  const toggleContact = () => {
    if (detailHeading) {
      return (
        <h3 className="font-display text-2xl tracking-widest text-homepageMantraText">
          Kontakt:
        </h3>
      )
    }
  }
  return (
    <div
      className="md:flex md:flex-row md:flex-wrap md:justify-between lg:w-full lg:flex-nowrap lg:justify-center"
      style={{ maxWidth: 1080 }}
    >
      <div className="md:ml-44 md:flex md:w-full md:flex-row md:justify-between lg:ml-0">
        <div className={`w-full font-medium ${margin()}`}>
          {children}
          {toggleContact()}
          <div className="my-2.5 font-navigation leading-7 text-contactWhite">
            <p>{company}</p>
            <p>{address}</p>
            <p>{region}</p>
          </div>
          <div className="font-navigation  leading-7 tracking-wider">
            <p>FON: {phoneNumber}</p>
            <p>FAX: {fax}</p>
            <p className="mt-1.5">E-Mail:{email}</p>
          </div>
        </div>
      </div>
      {/*{detailContact.map((contetnt, index) => (*/}
      {/*  <div>{c}</div>*/}
      {/*))}*/}
    </div>
  )
}
