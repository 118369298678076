import { useEffect, useState } from "react"

interface WindowSize {
  width: number | undefined
  height: number | undefined
}

export const useWindowSize = (): WindowSize | undefined => {
  const [windowSize, setWindowSize] = useState<WindowSize | undefined>({
    width: undefined,
    height: undefined,
  })

  useEffect(() => {
    const handler = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      })
    }

    // Set size at the first client-side load
    handler()

    window.addEventListener("resize", handler)

    // Remove event listener on cleanup
    return () => {
      window.removeEventListener("resize", handler)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return windowSize
}
