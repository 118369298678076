import React from "react"
import { Contact, ContactDetails } from "../Contact/Contact"
import { FooterLegalPages } from "../FooterLegalPages/FooterLegalPages"
import { LegalPage } from "../Layout/Layout"

export interface FooterProps {
  contact: ContactDetails
  tags: LegalPage[]
}

export const Footer: React.FC<FooterProps> = ({ tags, contact }) => {
  const displayImage = (sourceUrl: string, website: string) => {
    return (
      <div className="w-full lg:w-3/12">
        <a href={website}>
          <img src={sourceUrl} alt="" />
        </a>
      </div>
    )
  }
  return (
    <section className="relative bottom-0 z-0 w-full bg-footerBlack font-titilliumWeb text-base text-white">
      <div
        className="relative bottom-4 flex w-full flex-wrap justify-between py-16 px-6 md:px-28 lg:mx-auto lg:justify-center lg:px-0 lg:py-18"
        style={{ maxWidth: 1362 }}
      >
        <FooterLegalPages tags={tags} />
        <div className="mb-16 w-full md:mb-0 md:w-8/12 lg:w-1/3">
          <Contact detailHeading={true} contact={contact} marginLeft={true} />
        </div>
        <div className="flex w-5/12 justify-start lg:w-1/3 ">
          {displayImage(
            "https://website0763311.live-website.com/wp-content/uploads/2021/03/facebook-180x180-1-150x150",
            "https://www.facebook.com/vitalist.yoga.pilates.balance",
          )}{" "}
          {displayImage(
            "https://website0763311.live-website.com/wp-content/uploads/2021/03/instagram-180x180-1-150x150",
            "https://www.instagram.com/vitalisthannover/",
          )}
        </div>
      </div>
    </section>
  )
}
